import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AlertModel } from 'src/app/model/alert.model';
import { AlertPriority, AlertPriorityDescription, AlertType, AlertTypeDescription, PermisionRuleOption, Permission, SimfAlertTypeDescription, OperationTypeDescription } from 'src/app/model/enums.enum';
import { AlertsService } from 'src/app/service/model/alerts.service';
import DateUtils from 'src/app/service/util/date-utils';
import { CentralFilterDialogComponent } from '../central-filter-dialog/central-filter-dialog.component';
import { CentralFilterModel } from '../central-filter-dialog/central-filter.model';
import { ServiceTypeDescription, SourceType } from '../../../model/enums.enum';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';
import { AuthorizationService } from '../../../service/authorization/authorization.service';
import { PermissionOptions } from '../../../model/field.model';
import { AuthenticationService } from '../../login/login-services';
import { ProfileModel } from '../../../model/profile.model';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { StorageService } from 'src/app/service/storage-service';
import { ESP } from 'src/app/common/constants';
import FieldUtils from 'src/app/service/util/field-utils';

@Component({
  selector: 'app-central-list',
  templateUrl: './central-list.component.html',
  styleUrls: ['./central-list.component.scss']
})
export class CentralListComponent implements OnInit, OnDestroy {

  loadingListService: LoadingListService = new LoadingListService();

  @Input() title: string;
  @Input() displayedColumns: string[];
  @Input() inMemoryDetailsAlert: AlertModel;
  @Input() showDetails: boolean;
  @Input() sourceApp: boolean;

  @Output() alertClicked = new EventEmitter<AlertModel>();
  @Output() locationClicked = new EventEmitter<AlertModel>();
  @Output() eventClicked = new EventEmitter();
  @Output() operationMessagesClicked = new EventEmitter();
  @Output() operationClicked = new EventEmitter();
  @Output() acknowledgeClicked = new EventEmitter<AlertModel>();
  @Output() dataSourceLoaded = new EventEmitter<AlertModel>();
  @Output() loggedUserPermission = new EventEmitter<ProfileModel>();

  filterModel:  CentralFilterModel;

  dataSource: MatTableDataSource<AlertModel>;
  model:      AlertModel[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** The structure to control grid selection  */
  selection = new SelectionModel<AlertModel>(true, []);

  // Context Menu
  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger, static: true }) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuSelectedItem; // Leave t as any, for now

  /** PAGINAÇÂO */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = environment.defaultPageSize;
  pageLength = undefined;

  private audio: HTMLAudioElement;

  /** ENUMS */
  alertTypeDescription      = AlertTypeDescription;
  serviceTypeDescription    = ServiceTypeDescription;
  simfAlertTypeDescription  = SimfAlertTypeDescription;
  operationTypeDescription = OperationTypeDescription

  /* Variável que controla o alerta atual selecionado para os detalhes */
  clickedAlert: AlertModel;

  /**User Profile */  
  permission = Permission;
  permisionRuleOption = PermisionRuleOption;

  authenticatedSubscription: Subscription;

  constructor(public logger:                NGXLogger,
              public authorizationService:  AuthorizationService,
              private dialog:               MatDialog,
              private authenticationService: AuthenticationService,
              protected toastr:               ToastrService,
              private storageService:           StorageService,
              private alertsService:        AlertsService) {}

  ngOnInit() {
    if(!this.filterModel){
      this.filterModel = new CentralFilterModel();      
    }
    
    this.filterModel.sourceTypes = (this.sourceApp) ? [SourceType.MOBILE_APP] : [SourceType.SIMF];
    this.filterModel.simfAlertTypes = (this.sourceApp) ? undefined: ['Threat.Type.Digging', 'Threat.Type.Mechanical_Digging', 'Threat.Type.Pig', 'Threat.Type.FibreBreak','Threat.Type.LeakNPP'];

    this.audio = new Audio();
    this.audio.src = "../../assets/sound/alert.wav";
    this.audio.load();

    this.subscribeAndLoadAll();

    this.sort.sort({id: "timestamp", start: 'desc', disableClear: false});
  }

  ngOnDestroy(){
    this.loadingListService.destroy();
    this.authenticatedSubscription?.unsubscribe();
  }

  /**
   * @description Subscribes to all entities changes and loads all data
   */
  subscribeAndLoadAll() {
    this.subscribeOnAuthenticatedStatus();
  }

  loadAlerts(){
    if (this.getShowSpinner()) // Controle para evitar que a primeira conexão com o Websocket dispare num novo load
      return;

    // lotaçoes especificas as que pertence o usuário 
     this.filterModel.placements = this.storageService.getSpecificPlacementIds();

    this.loadingOn();      
    this.alertsService.loadFromServerBySearch(this.filterModel).pipe(first()).subscribe( (entities : AlertModel[]) => {
      this.model = entities;
      this.model.sort((alert1, alert2) => (alert1.timestamp < alert2.timestamp) ? 1 : -1);
      this.updateSimfInfo();
      this.buildDataSource(true);      
    }, error => {
      if(error.status == 413){ // não é um erro, a consulta foi maior do límite (2000 registros)
        this.toastr.warning("Sua consulta retornou mais de 2000 registros, por favor refina sua busca.");
        this.loadingOff();
      }else{ 
        this.logger.error(error);
      }
    }
    ,() => {
      this.loadingOff();
    });
  }
  
  /**verifica o tipo de alertas que o usuário tem acesso */
  filterAlertsToProfile(){ 
      if(this.authorizationService.userHasPermission(this.permission.LIST_ALERTS)){
          let permission_option: PermissionOptions = this.authorizationService.getUserPermissionOptions(this.permission.LIST_ALERTS);
          if(permission_option.option == this.permisionRuleOption.SOME_FIELDS){
            this.filterModel.types = permission_option.fields;
      }      
    }
  }

  subscribeOnAuthenticatedStatus(){
    this.authenticatedSubscription = this.authenticationService.authenticated$.subscribe(authenticated => {
      this.logger.debug('CentralListComponent.OnAuthenticatedStatus() - ' + authenticated);      
      if (authenticated) {
        this.filterAlertsToProfile();
        this.loggedUserPermission.emit();
        this.loadAlerts();
      }
    });
  }

  getShowSpinner() {    
    return this.loadingListService.getShowSpinner();
  }

  loadingOn() {    
    this.loadingListService.loadingOn();
  }

  loadingOff() {    
    this.loadingListService.loadingOff();
  }

  buildDataSource(cleanSelection: boolean){
    this.dataSource = new MatTableDataSource(this.model);
    this.dataSource.paginator = this.paginator;

    this.dataSource.filterPredicate = (entity: AlertModel, filter: string): boolean => {
      return this.getStringEntityForFilter(entity).indexOf(filter) != -1;
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };

    this.dataSourceLoaded.emit();

    this.dataSource.sort = this.sort;

    if (cleanSelection) this.selection.clear();
  }

  updateSearchFilter(filter){
    this.dataSource.filter = filter;
  }

  protected getStringEntityForFilter(alert: AlertModel): string {
    const identifier = this.lowerAndTrimText(alert.identifier);
    const timestamp = (alert.timestamp) ? DateUtils.timestampToStringInMinutes(alert.timestamp) : '';
    const alertType = this.lowerAndTrimText(this.sourceApp? this.alertTypeDescription[alert?.alertType]: (this.simfAlertTypeDescription[alert?.simfAlertType]? this.simfAlertTypeDescription[alert?.simfAlertType]: alert?.simfAlertType));
    const observedArea = this.lowerAndTrimText(alert.observedArea);
    const observation = this.lowerAndTrimText(alert.observation);

    let extra;
    // App Móvel
    if (this.sourceApp){
      const patrolTeam = (alert.patrolTeam) ? this.lowerAndTrimText(alert.patrolTeam.name) : '';
      const operationIdentifier = this.lowerAndTrimText(alert.operationIdentifier); 
      const serviceType = alert.patrolTeam? this.lowerAndTrimText(this.serviceTypeDescription[alert.patrolTeam.serviceType]): '';
      const placement = alert.patrolTeam?.company?.placement? this.lowerAndTrimText(alert.patrolTeam.company.placement.name): '';
      const band = this.lowerAndTrimText(alert.band);
      const duct = this.lowerAndTrimText(alert.duct);
      const km = this.lowerAndTrimText(alert.km);

      extra = patrolTeam + ESP + operationIdentifier + ESP + serviceType + ESP + placement + ESP + band + ESP + duct + ESP + km;
    }
    else{ // SIMF
      const duration = this.getDuration(alert)
      const scalePoint = this.lowerAndTrimText(alert.simf.scalePoint);
      const zoneName = this.lowerAndTrimText(alert.simf.zoneName);
      const cpsNumber = this.lowerAndTrimText(alert.simf.cpsNumber);
      const endChannel = this.lowerAndTrimText(alert.simf.endChannel);

      extra = duration + ESP + scalePoint + ESP + zoneName + ESP + cpsNumber + ESP + endChannel;
    }
    
    // Não está na tabela, mas permitimos a busca
    const prioritiy = this.lowerAndTrimText(AlertPriorityDescription[alert.alertPriority]);
    const eventIdentifier = this.lowerAndTrimText(alert.eventIdentifier);

    return identifier + ESP + timestamp + ESP + prioritiy + ESP + alertType + ESP + observation + ESP + eventIdentifier + ESP + observedArea + ESP + extra;
  }

  /* reducer method */
  getProperty (o, i) {
    if ( !o ) { return ''; }
    return o[i];
  }

  /** Método utilitário para passar strings para caixa alta e remover espaços (se existirem) */
  protected lowerAndTrimText(text: string) {
    if (!text) {
      return '';
    }
    text = (typeof text == 'string') ? text : String(text);
    return text.trim().toLowerCase();
  }

  /* Retorna a propriedade definida pelo caminho */
  getPropertyByPath(item: Object, property: string){
    let value = property.split('.').reduce(this.getProperty, item);
    if (property === 'alertType') {
      if (this.sourceApp)
        value = AlertTypeDescription[value];
      else {
        value = (item as any).simfAlertType;
        if (SimfAlertTypeDescription[value]) value = SimfAlertTypeDescription[value];
      }
    }
    else if (property === 'duration') {
      value = this.getDuration(item);
    }
    else if (property === 'patrolTeam.serviceType') {
      value = ServiceTypeDescription[value];
    }

    if (typeof value === 'string'){
      value = this.lowerAndTrimText(value);
    }
    return value ? value : '';
  }

  updateSimfAlert(alert){
    let simf;

    if (alert.description.indexOf("OptaSense") != -1) {
      return;
    }

    try {
      simf = JSON.parse(alert.description);
    }catch (e) {
      return;
    }

    alert.simf = simf;

    // Compatibilidade para alertas antigos
    if (!alert.simfAlertType) {
      alert.simfAlertType = alert.simf.alertType;
    }

    alert.simf.scalePoint = FieldUtils.kmSimfToString(alert.simf.scalePoint);
    alert.scalePoint = alert.simf.scalePoint;
  }

  updateSimfInfo(){
    this.model.forEach((alert: AlertModel) => {
      if (alert.sourceType == SourceType.SIMF && alert.description) {
        this.updateSimfAlert(alert);
      }
    });
  }

  getDuration(alert): string {
    if (alert && alert.sourceType == SourceType.SIMF && alert.simf) {
      if (alert.simf.startTime && alert.simf.endTime) {
        return DateUtils.durationToTimeString(alert.simf.endTime - alert.simf.startTime);
      }
    }

    return '';
  }

  //########################################################
  // HEADER ACTIONS
  //########################################################

  getListCount(){
    return this.dataSource? this.dataSource.filteredData.length: 0;
  }

  openFilterDialog(): void {

    const dialogRef = this.dialog.open(CentralFilterDialogComponent, {
      width: '800px',
      data: this.filterModel,
      panelClass: 'sipd-modal'
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.loadAlerts();
      }
    });
  }

  //########################################################
  // EXTERNAL NOTIFICATIONS
  //########################################################

  // Filtra apenas o que foi
  filterNewAlert(alert: AlertModel): boolean{
    if (this.filterModel.startDate && this.filterModel.endDate) {
      if (alert.timestamp < this.filterModel.startDate ||
          alert.timestamp > this.filterModel.endDate) {
        return false;
      }
    } else if (this.filterModel.startDate) {
      if (alert.timestamp < this.filterModel.startDate) {
        return false;
      }
    } else if (this.filterModel.endDate) {
      if (alert.timestamp > this.filterModel.endDate) {
        return false;
      }
    }

    if (this.filterModel.priorities && this.filterModel.priorities.length > 0) {
      if (!this.filterModel.priorities.find(alertPriority => alertPriority == alert.alertPriority))
        return false;
    }

    if (this.sourceApp){    
      if (this.filterModel.patrolTeams && this.filterModel.patrolTeams.length > 0) {
        if (!alert.patrolTeam) return false;
        if (!this.filterModel.patrolTeams.find(patrolTeamId => patrolTeamId == alert.patrolTeam.id))
          return false;
      }

      if (this.filterModel.vehicles && this.filterModel.vehicles.length > 0) {
        if (!alert.patrolTeam || !alert.patrolTeam.vehicle) return false;
        if (!this.filterModel.vehicles.find(vehiclePlate => vehiclePlate == alert.patrolTeam.vehicle.plate))
          return false;
      }

      if (this.filterModel.types && this.filterModel.types.length > 0) {
        if (!this.filterModel.types.find(alertType => alertType == alert.alertType))
          return false;
      }

      if (this.filterModel.sourceTypes && this.filterModel.sourceTypes.length > 0) {
        if (!this.filterModel.sourceTypes.find(sourceType => sourceType == alert.sourceType))
          return false;
      }
    }
    else {
      if (this.filterModel.simfAlertTypes && this.filterModel.simfAlertTypes.length > 0) {
        if (!this.filterModel.simfAlertTypes.find(alertType => alertType == alert.simfAlertType))
          return false;
      }

      if (this.filterModel.startScalePoint && this.filterModel.endScalePoint) {
        if (alert.scalePoint < this.filterModel.startScalePoint ||
            alert.scalePoint > this.filterModel.endScalePoint) {
          return false;
        }
      } else if (this.filterModel.startScalePoint) {
        if (alert.scalePoint < this.filterModel.startScalePoint) {
          return false;
        }
      } else if (this.filterModel.endScalePoint) {
        if (alert.scalePoint > this.filterModel.endScalePoint) {
          return false;
        }
      }
  
      if (this.filterModel.cpsNumber) {
        if (alert.cpsNumber != this.filterModel.cpsNumber) {
          return false;
        }
      }

      if (this.filterModel.startChannel && this.filterModel.endChannel) {
        if (alert.endChannel < this.filterModel.startChannel ||
            alert.endChannel > this.filterModel.endChannel) {
          return false;
        }
      } else if (this.filterModel.startChannel) {
        if (alert.endChannel < this.filterModel.startChannel) {
          return false;
        }
      } else if (this.filterModel.endChannel) {
        if (alert.endChannel > this.filterModel.endChannel) {
          return false;
        }
      }
    }

    return true;
  }

  checkAlertSound(alert: AlertModel) {
    if(alert.alertPriority == AlertPriority.HIGH && 
      alert.sourceType == SourceType.MOBILE_APP &&
      (alert.alertType == AlertType.ATTENTION_PATROL_CHECK || alert.alertType == AlertType.PANIC)){
      return true;
      }
    return false;
  }

  /** Insere um alerta no grid - chamado ao receber um novo alerta */
  insertNewAlert(alert: AlertModel) {
    if (!this.filterNewAlert(alert))
      return;

    if (alert.sourceType == SourceType.SIMF && alert.description) {
      this.updateSimfAlert(alert);
    }

    this.model.splice(0, 0, alert); // Insere no topo sempre, a ordenação corrente vai colocar no lugar certo na tela
    this.buildDataSource(false);

    if(this.checkAlertSound(alert)){
      this.audio.play();
    }
  }

  /** Altera um alerta no grid - chamado ao atualizar um alerta existente */
  updateExistingAlert(existingAlertPos, alert: AlertModel) {
    if (!this.filterNewAlert(alert))
      return;
    
    if (alert.sourceType == SourceType.SIMF && alert.description) {
      this.updateSimfAlert(alert);
    }

    this.model[existingAlertPos] = alert;
    this.buildDataSource(false);
  }

  //########################################################
  // CONTEXT MENU ACTIONS
  //########################################################

  onLocationClick(alert: AlertModel) {
    this.locationClicked.emit(alert);
  }

  hasLocation(alert: AlertModel): boolean {
    return alert && alert.location.coordinates[0] != 0 && alert.location.coordinates[1] != 0;
  }

  hasOperation(alert: AlertModel): boolean {
    return !!alert && !!alert.operationId;
  }

  onCreateEventClick(alert: AlertModel) {
    this.eventClicked.emit({alert: alert, create: true});
  }

  onLinkEventClick(alert: AlertModel) {
    this.eventClicked.emit({alert: alert, link: true});
  }

  onUnLinkEventClick(alert: AlertModel) {
    this.eventClicked.emit({alert: alert, link: false});
  }

  canUnLinkEvent(alert: AlertModel) {
    return alert && alert.eventIdentifier;
  }

  onAcknowledgeClick(alert: AlertModel) {
    this.acknowledgeClicked.emit(alert);
  }

  canAcknowledge(alert: AlertModel) {
    return alert && !alert.acknowledged; // Não preciso verificar o alert.acknowledgmentAuthor aqui
  }

  onMessagesClick(alert: AlertModel) {
    this.operationMessagesClicked.emit({ operationId: alert.operationId, operationType: alert.operationType});
  }

  onOperationClick(alert: AlertModel) {
    this.operationClicked.emit({ operationId: alert.operationId, operationType: alert.operationType});
  }

  //########################################################
  // GRID SELECTION CONTROL
  //########################################################

  /** The tip for the checkbox on the passed row */
  checkboxTip(row?: AlertModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Desmarcar' : 'Selecionar'} Todos`;
    }
    return `${this.selection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Item`;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.selection.hasValue() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.filteredData.length : 0;
    return numSelected === numRows;
  }

  openContextMenu(event: MouseEvent, row) {
    event.preventDefault();
    this.contextMenuSelectedItem = row;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  //########################################################
  // GRID ACTIONS
  //########################################################

  onCheckboxClick(alert: AlertModel){
    this.selection.toggle(alert);
  }

  onAlertClick(alert: AlertModel) {
    this.showAlertDetails(alert);
  }

  isAlertInDetails(alert: AlertModel){
    if (this.showDetails && alert && this.inMemoryDetailsAlert && alert.id == this.inMemoryDetailsAlert.id)
      return true;
    else
      return false;
  }

  arrowUpEvent( ){
    let sortedData = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    let index = sortedData.findIndex( (alert: AlertModel) => this.clickedAlert.id === alert.id);
    if (index==0) return;
    index--;
    const alert = sortedData[index];
    this.showAlertDetails(alert);
  }

  arrowDownEvent(){
    let sortedData = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    let index = sortedData.findIndex( (alert: AlertModel) => this.clickedAlert.id === alert.id);
    if (index == (this.dataSource.filteredData.length-1)) return;
    index++;
    const alert = sortedData[index];
    this.showAlertDetails(alert);
  }

  /* Procura o objeto(alerta) na tabela a partir do index atual */
  showAlertDetails(alert: AlertModel){
    this.alertClicked.emit(alert);
    this.clickedAlert = alert;
  }

  onPageChanged(event){
    if (environment.useServerPagination) {
      this.loadingOn();
      this.alertsService.loadFromServerBySearch(this.filterModel).pipe(first()).subscribe( (result) => {
        this.model = result;
        this.model.sort((alert1, alert2) => (alert1.timestamp < alert2.timestamp) ? 1 : -1);
        this.updateSimfInfo();
        this.buildDataSource(true);
      }, error => {
        this.logger.error(error);
      },
      () => {
        this.loadingOff();
      });
    }
  }
}
